import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';

import * as moment from 'moment-mini-ts';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Subject } from 'rxjs/internal/Subject';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmActionDialogComponent } from '@app/modules/dialogs/confirm-action-dialog/confirm-action-dialog.component'
import { RecordAmountSelectorDialogComponent } from '@app/modules/dialogs/record-amount-selector-dialog/record-amount-selector-dialog.component';
import { MintNotificationComponent } from '../modules/notifications/mint-notification/mint-notification.component';
import { ExpiringCache } from '@app/models/expiringCache';
import { LayoutField } from '@app/models';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class SharedUtilsService {
  private moduleUpdatesObs$ = new Subject<any>();
  private showEditOnLoad: boolean = false;

  public phoneMask: any[] = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public zipMask: any[] = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public minuteMask: any[] = [/^\d+$/];

  public previousRoute: string = '';
  public reloadedComponentPreviousRoute: string = ''; // Have to do this because above one is used elsewhere and saves even reloaded routes
  public reloadCount = 0;

  public companyName: string = 'Yeehro';
  public storeCurrencyText: string = 'Eddie Coins';

  public checkboxDropdownList = [{ label: '', value: null }, { label: 'Yes', value: true }, { label: 'No', value: false }];
  public activedownList = [{ label: 'Active', value: true }, { label: 'Inactive', value: false }];

  public appsList = ['YeeHro CRM', 'Mint', 'Silk'];

  public stateAbbrList = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];

  public stateListObj = {
    AZ: 'Arizona',
    AL: 'Alabama',
    AK: 'Alaska',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
  };

  public dateRangeList = [
    { label: 'Is Empty', value: 'empty' },
    { label: 'Is Not Empty', value: 'not-empty' },
    { label: 'On', value: 'On' },
    { label: 'On or Before', value: 'Before' },
    { label: 'On or After', value: 'After' },
    { label: 'Between', value: 'Between' },
    { label: 'Last Year', value: 'last_year' },
    { label: 'This Year', value: 'this_year' },
    { label: 'This Month', value: 'this_month' },
    { label: 'Last Month', value: 'last_month' },
    { label: 'Last 30 Days', value: '-30' },
    { label: 'Last Week', value: '-7' },
    { label: 'Yesterday', value: '-1' },
    { label: 'Today', value: '0' },
    { label: 'Tomorrow', value: '1' },
    { label: 'Next Week', value: '7' },
    { label: 'Next 30 Days', value: '30' }
  ];

  public nonRangeList = [
    { label: 'Last Month', value: 'last_month' },
    { label: 'Last 30 Days', value: '-30' },
    { label: 'This Month', value: 'this_month' },
    { label: 'Last Week', value: '-7' },
    { label: 'Yesterday', value: '-1' },
    { label: 'Today', value: '0' },
    { label: 'Tomorrow', value: '1' },
    { label: 'Next Week', value: '7' },
    { label: 'Next 30 Days', value: '30' }
  ];

  public nonFutureRangeList = [
    { label: 'On', value: 'On' },
    { label: 'On or Before', value: 'Before', weight: 1 },
    { label: 'On or After', value: 'After', weight: 2 },
    { label: 'Between', value: 'Between', weight: 3 },
    { label: 'This Month', value: 'this_month' },
    { label: 'Last Month', value: 'last_month' },
    { label: 'Last 30 Days', value: '-30', weight: 4 },
    { label: 'Last Week', value: '-7', weight: 5 },
    { label: 'Yesterday', value: '-1', weight: 6 },
    { label: 'Today', value: '0', weight: 7 }
  ];

  // these date fields will trigger the date picker to show
  public datePickerFieldsList = ['Before', 'After', 'Between', 'On'];
  public focusFilterValues = [];

  // Options for number range select
  public numberRangeList = [
    { label: 'Less Than', value: 'Less' },
    { label: 'Greater Than', value: 'Greater' },
    { label: 'Equal To', value: 'Equals' },
    { label: 'Between', value: 'Between' }
  ];

  // these number range fields will show one or two number fields depending on selection
  public numberPickerFieldsList = ['Less', 'Greater', 'Equals', 'Between'];

  public emojiTextKeys = {
    'sad': '😥',
    'happy': '😊',
    'like': '👍',
    'nervous': '😟',
    'love': '❤️',
    'angry': '😡',
    'interested': '🤔',
    'confused': '😕',
    'shocked': '😱',
    'cold': '🥶',
    'exploding': '🤯',
  };

  // Add to this if more formats are added to granularity label functionality.
  public granularityDateFormats = ['MM-DD-YYYY, h a', 'MM-DD-YYYY', 'MMM YYYY', 'YYYY', 'MMM YYYY'];

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private router: Router,
    public snackBar: MatSnackBar,
    private confirmActionDialog: MatDialog,
  ) { }


  // used for subscribing to module updates (updating calls grid when quick create call is triggered)
  listenForModuleUpdates() {
    return this.moduleUpdatesObs$;
  }
  // used for triggering module updates (updating calls grid when quick create call is triggered)
  announceModuleUpdates(_moduleName: string) {
    this.moduleUpdatesObs$.next(_moduleName);
  }


  setEditOnLoad(_editOnLoad: boolean): void {
    this.showEditOnLoad = _editOnLoad;
  }

  getEditOnLoad() {
    return this.showEditOnLoad;
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  getLinkBaseForRecord(modulePassedIn: string) {
    let returnString = '';
    let moduleName = '';

    if (modulePassedIn) {
      moduleName = modulePassedIn.toLowerCase();
    }

    // console.log('moduleName: ', moduleName);

    if (moduleName === 'job') {
      returnString = 'jobs';
    } else if (moduleName === 'contact') {
      returnString = 'contacts';
    } else if (moduleName === 'location') {
      returnString = 'locations';
    } else if (moduleName === 'provider') {
      returnString = 'providers';
    } else if (moduleName === 'task') {
      returnString = 'tasks';
    } else if (moduleName === 'vendor') {
      returnString = 'vendors';
    } else if (moduleName === 'user') {
      returnString = 'users';
    } else if (moduleName === 'message') {
      returnString = 'messages/detail';
    } else if (moduleName === 'notes') {
      returnString = 'module/notes';
    } else {
      returnString = moduleName;
    }

    // console.log('Link being returned: ', returnString);

    return returnString;
  }


  dropdownIdCompareFn(c1: Object, c2: Object): boolean {
    return c1 && c2 ? c1['_id'] === c2['_id'] : c1 === c2;
  }


  // Compares objects, strings, or ids
  compareObjectsOrIDs(item1, item2): boolean {
    if (item1 == undefined || item2 == undefined) return item1 === item2; // One of the items is null or undefined
    else if (item1['_id'] == undefined && item2['_id'] == undefined) return item1 === item2; // Neither item has an id
    else if (item1['_id'] != undefined && item2['_id'] != undefined) return item1['_id'] === item2['_id']; // Both items have an id
    else if (item1['_id'] != undefined && item2['_id'] == undefined) return item1['_id'] === item2; // Only first item has an id
    else if (item1['_id'] == undefined && item2['_id'] != undefined) return item1 === item2['_id']; // Only second item has an id
    else return item1 === item2; // Just incase nothing was matched
  }


  showStringAsTime(timeString) {
    if (!timeString) {
      return '';
    }

    const timeParts = timeString.split(':');

    let hour = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    let amPm = 'AM';

    if (hour >= 12) {
      amPm = 'PM';
    }

    if (hour > 12) {
      hour -= 12;
    }

    return `${hour}:${minutes} ${amPm}`;
  }


  setTime(_date, _time): Date {
    const dateMoment = moment(_date);
    const dateStr = dateMoment.format("MM/DD/YYYY");
    const date = moment(dateStr);
    const time = moment(_time, 'HH:mm A');

    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    });

    // console.log('Date/Time on campaign set to: ', date.format('MMMM Do YYYY, h:mm A'));

    return date.toDate();
  }


  reloadComponentByRoute(_route: string) {
    this.previousRoute = _route;
    this.router.navigateByUrl('/app/load', { skipLocationChange: true }).then(() => this.router.navigate([_route]));
  }


  reloadComponentByAdminRoute(_route: string) {
    this.previousRoute = _route;
    this.router.navigateByUrl('/admin', { skipLocationChange: true }).then(() => this.router.navigate([_route]));
  }


  convertToMilitaryTime(timeString) {
    if (!timeString || (!timeString.toLowerCase().includes('am') && !timeString.toLowerCase().includes('pm'))) {
      return timeString;
    }

    const timeAmPmParts = timeString.split(' ');
    const timeParts = timeAmPmParts[0].split(':');
    const amPmPart = timeAmPmParts[1];
    let hour = timeParts[0];
    const minutes = timeParts[1];

    if (amPmPart.toLowerCase() === 'pm') {
      hour = parseInt(hour) + 12;
    } else {
      hour = '0' + hour;
    }

    return `${hour}:${minutes}`;
  }


  showStringAsTimeNoAmPm(timeString) {
    if (!timeString) {
      return '';
    }

    const timeParts = timeString.split(':');

    let hour = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    let amPm = 'AM';

    if (hour >= 12) {
      amPm = 'PM';
    }

    if (hour > 12) {
      hour -= 12;
    }

    let returnString = `${hour}:${minutes} ${amPm}`;

    if (timeString.toLowerCase().includes('am') || timeString.toLowerCase().includes('pm')) {
      returnString = `${hour}:${minutes}`;
    }

    return returnString;
  }


  /** 
   * Used to get changes between objects. Can use for Activity Log (AUDIT) as well as user profile changes. 
   * * a is original object, b is object to look for changes in
  */
  getDiff(a, b) {
    // console.log('Checking for differences between..');
    // console.log('a: ', a);
    // console.log('b: ', b);

    const changesDetected = [];

    for (let prop in b) {
      const fieldDefs = { name: prop, value: '' };

      // console.log('Checking if prop is the same: ', prop);
      // console.log('A: ', a[prop]);
      // console.log('B: ', b[prop]);

      if (typeof b[prop] == 'undefined') {
        // this field has been removed from array b
        fieldDefs.value = 'REMOVED';
        changesDetected.push(fieldDefs);
      }
      else if (typeof a[prop] == 'undefined') {
        // this field was not in the original array. Has the field been added since?
        fieldDefs.value = b[prop];
        changesDetected.push(fieldDefs);
      }
      else if (JSON.stringify(a[prop]) !== JSON.stringify(b[prop])) {
        // Field change detected for prop: ', prop);
        fieldDefs.value = b[prop];
        changesDetected.push(fieldDefs);
      }
    }

    return changesDetected;
  }



  flatten(arr) {
    return arr.reduce((flat, toFlatten) => {
      return flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten);
    }, []);
  }



  showInvalidFormMessage(_form: UntypedFormGroup) {
    for (let _control in _form.controls) {
      if (_form.controls[_control].status === 'INVALID') {
        this.showErrorDialog(_control + ' is Invalid. Cannot save.')
      }
    }
  }


  /** 
   * Takes a string passed in and returns the string with the first character uppercased
  */
  upperCaseFirstCharacter(_stringToTransform: string) {
    if (_stringToTransform) {
      return _stringToTransform.charAt(0).toUpperCase() + _stringToTransform.slice(1);
    } else {
      return '';
    }
  }


  /** 
   * Takes a string passed in and returns all the words in the string with the first character uppercased
  */
  titleCaseString(_stringToTransform: string) {
    if (_stringToTransform) {
      return _stringToTransform.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
      }).join(' ');
    } else {
      return '';
    }
  }


  getNameFromModule(moduleType, moduleRecord) {
    const moduleLowercase = moduleType.toLowerCase();
    let recordName = '';

    // console.log('Getting record name from: ', moduleLowercase);
    // console.log('Module Record: ', moduleRecord);

    if (moduleLowercase === 'task') {
      recordName = `Task for ${moduleRecord.assigned_to.last_name}, ${moduleRecord.assigned_to.first_name}`;
    } else {
      recordName = moduleRecord.name;
    }

    return recordName;
  }


  /** 
   * Takes a string passed in and displays a custom popup in notification fashion for 10 seconds
  */
  showNotification(_notificationMessage: string) {
    this.snackBar.openFromComponent(MintNotificationComponent, {
      duration: 1000,
      // horizontalPosition: 'left',
      horizontalPosition: 'start', //'start' | 'center' | 'end' | 'left' | 'right'
      data: {
        class: 'success',
        message: _notificationMessage
      }
    });
  }


  showErrorDialog(_errorMessage: string, _duration: number = 1000) {
    this.snackBar.openFromComponent(MintNotificationComponent, {
      duration: _duration,
      horizontalPosition: 'start',
      data: {
        class: 'danger',
        message: 'Error: ' + _errorMessage
      }
    });
  }


  /** 
   * Shows custom snackbar notification. Accepts string to show, a duration of milliseconds to show for, and a class to apply to the notification text
  */
  showMintNotificationWithOptions(_dialogMessage: string, _duration: number = 1000, _className: string = 'primary') {
    this.snackBar.openFromComponent(MintNotificationComponent, {
      duration: _duration,
      horizontalPosition: 'start',
      data: {
        class: _className,
        message: _dialogMessage
      }
    });
  }


  /** 
   * Used for storing cacheable data. Pass in number of minutes you want an item to expire in. Returns time in milliseconds
  */
  getNewExpiresTime(_expireMinutes: number): number {
    const expirationMS = _expireMinutes * 60 * 1000; // 1000 (1 second) * 60 = 1 minute. Multiply by number of minutes we want
    return new Date().getTime() + expirationMS;
  }


  clearKeyFromLocal(key) {
    this.storage.remove(key);
  }


  clearAllStorage() {
    this.storage.clear();
  }



  // used for storing cacheable data


  isStorageValid(_storageItem): boolean {
    let isValid = false;

    if (_storageItem && _storageItem.itemCache && new Date().getTime() < _storageItem.expiresAt) {
      isValid = true;
    }

    return isValid;
  }


  // returns all fields the ai search needs. have to get relationships used in grid layout as well
  async getSearchFields(_module): Promise<any[]> {
    return new Promise(async (resolve) => {
      // console.log('Getting fields for module: ', _module);

      if (_module && _module !== undefined) {
        const _fields = _module.fields;
        const gridLayout = (_module.layouts && _module.layouts.length) ? _module.layouts.find(_l => _l.view === 'grid') : null;

        if (gridLayout && gridLayout.sections && gridLayout.sections.length) {
          const defaultCols = gridLayout.sections.find(_s => _s.sectionName === 'Default');
          // console.log('Default columns: ', defaultCols);

          if (defaultCols && defaultCols.sectionFields) {
            // console.log('Get relate fields from: ', defaultCols.sectionFields);
            defaultCols.sectionFields
              .filter(_sf => _sf.type && _sf.type.inputType === 'Relate')
              .forEach(_rf => {
                if (!_fields.find(_f => _f.fieldName === _rf.fieldName)) _fields.push(_rf);
              });
          }
        }

        // console.log('Fields: ', _fields);

        const addressFields = _fields.filter(_f => ((_f.type && _f.type.inputType && _f.type.inputType === 'Address') || _f.inputType === 'Address'));

        // const addressFields = _fields.filter(_f => _f.inputType === 'Address');
        const addressParts = ['street', 'city', 'state', 'zip'];

        addressFields.forEach(_af => {
          // console.log('Address Field: ', _af);

          addressParts.forEach(_ap => {
            const addressField = new LayoutField();

            addressField.fieldName = _af.fieldName + '.' + _ap;
            addressField['inputType'] = 'Address Part';
            addressField.type = { inputType: 'Address Part', fieldType: 'String', schemaType: 'String' };
            addressField.label = _af.label + ' - ' + _ap;

            _fields.push(addressField);
          });
        });

        if (_module.name === 'leads') {
          const has_phone_field = new LayoutField();

          has_phone_field.fieldName = 'has_phone';
          has_phone_field['inputType'] = { inputType: "Checkbox", schemaType: "Boolean" };
          has_phone_field.type = { inputType: "Checkbox", fieldType: 'Boolean', schemaType: "Boolean" };
          has_phone_field.label = 'Has Phone';

          _fields.push(has_phone_field);
        }

        resolve(_fields);
      } else {
        resolve([]);
      }
    });
  }


  updateStorageExpiration(_storageItem): ExpiringCache {
    // const district = this._districtService.getCurrentDistrictBeingViewed();
    // console.log('Will save with district of: ', district);

    _storageItem.expiresAt = this.getNewExpiresTime(15); // store data for 15 minutes before refreshing

    return _storageItem;
  }


  dateAsString(_date: Date) {
    return (_date.getMonth() + 1) + "-" + _date.getDate() + "-" + _date.getFullYear();
  }


  /** 
   * Used for storing objects in local storage. Will assign as key passed in
  */
  saveInLocal(_key: string, _object: any): void {
    // console.log('Saving value for: ', _key);
    // console.log('Object being stored: ', _object);

    try {
      this.storage.set(_key, JSON.stringify(_object));
    }
    catch (e) {
      console.log("Local Storage is full, Please empty data");
      console.log(e);
      // fires When localstorage gets full
      // you can handle error here or empty the local storage

      // const storageSize = Math.round(JSON.stringify(localStorage).length / 1024);
      // console.log("LIMIT REACHED: " + storageSize + "K");

      this.storage.clear();
    }
  }


  recordWithRelatesAsIds(_currentRecord): Promise<any> {
    return new Promise(async (resolve) => {
      const newRecord = { ..._currentRecord };

      // console.log('Record before replacing relates: ', newRecord);

      Object.keys(newRecord).map(_key => {
        // console.log('Key: ', _key);
        // check if this field is a relate
        if (newRecord[_key] && newRecord[_key]._id) newRecord[_key] = newRecord[_key]._id;
      });

      resolve(newRecord);
    });
  }


  /** 
   * Used for retrieving object from local storage based on key passed in
  */
  getFromLocal(_key: string) {
    const recordCheck = this.storage.get(_key);
    // const recordCheck = localStorage.getItem(_key);
    if (!recordCheck) return null;

    return JSON.parse(recordCheck);
  }


  getStudioUploadPathForModuleImage(_module) {
    if (_module && (_module.profileImage && _module.profileImage !== undefined)) return 'https://studio.yeehro.com' + _module.profileImage;
    else return '/uploads/system_images/lead_bg_1.jpg';
  }


  /** 
   * Used to see if a value is a type of Array
  */
  isArray(value) {
    let isTypeArray = false;
    if (value && typeof value === 'object' && value.constructor === Array) isTypeArray = true;
    return isTypeArray;
  }


  /*   getCorrectModuleHeaderImagePath(_module) {
      if ( _module && ( _module.profileImage && _module.profileImage !== undefined ) ) {
        this.headerImage = this._sharedUtilsService.getCorrectImagePath(this.leadModule.profileImage);
      } else return '/uploads/system_images/lead_bg_1.jpg';
  
  
      return 'https://studio.yeehro.com' + _imagePath;
    } */


  async confirmationDialogPromise(_dialogContentObject): Promise<boolean> {
    return new Promise(async (resolve) => {
      const dialogRef = this.confirmActionDialog.open(ConfirmActionDialogComponent, {});
      const instance = dialogRef.componentInstance;
      instance.title = _dialogContentObject.title;
      instance.textToDisplay = _dialogContentObject.textToDisplay;
      instance.items = _dialogContentObject.items;
      instance.buttonsInfo = _dialogContentObject.buttonsInfo;

      const _confirmationResult = await lastValueFrom(dialogRef.afterClosed());
      if (_confirmationResult === true) resolve(true);
      else resolve(false);
    });
  }


  async triggerNumberOfRecordsSelector(_dataLength: number): Promise<number> {
    return new Promise(async (resolve) => {
      const dialogRef = this.confirmActionDialog.open(RecordAmountSelectorDialogComponent, { disableClose: true, panelClass: 'user-search' });
      const instance = dialogRef.componentInstance;
      instance.maxNumberValue = _dataLength;

      const _numberOfRecordsResult = await lastValueFrom(dialogRef.afterClosed());
      if (_numberOfRecordsResult) resolve(_numberOfRecordsResult);
      else resolve(null);
    });
  }


  // Simple groupBy that returns deep nested objects name field if present and replaces undefined fields with a message
  groupBy(array, key): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let group = array.reduce((result, currentValue) => {
        let currentField = currentValue[key];

        if (!currentField || currentField === undefined) currentField = '*Field Not Set';
        else if (currentField['_id'] != undefined) currentField = (currentField['name'] != undefined) ? currentField['name'] : '*Name Not Set';

        if (!result[currentField]) result[currentField] = [];
        result[currentField].push(currentValue);

        return result;
      }, []); // {}

      resolve(group);
    });
  }


  dateMomentComparer(date1, date2, granularity) {
    // console.log('Date 1: ', date1);
    // console.log('Date 2: ', date2);
    // console.log('granularity: ', granularity);

    let formatter = 'MM-DD-YYYY';

    if (granularity === 'h' || granularity === 'hours') {
      formatter = 'MM-DD-YYYY, h a';
    } else if (granularity === 'w' || granularity === 'weeks') {
      formatter = 'MM-DD-YYYY';
    } else if (granularity === 'm' || granularity === 'months') {
      formatter = 'MMM YYYY';
    } else if (granularity === 'y' || granularity === 'years') {
      formatter = 'YYYY';
    } else if (granularity === 'q' || granularity === 'quarters') {
      formatter = 'MMM YYYY';
    }

    return moment(date1, formatter).isSame(moment(date2), granularity);
  }


  getDateGranularityLabel(_val, _spec) {
    let momentSpecificity = 'MM-DD-YYYY'; // default to day

    let parsedString = moment(_val).format(momentSpecificity);
    // console.log('_spec: ', _spec);

    if (_spec === 'h' || _spec === 'hours') {
      momentSpecificity = 'MM-DD-YYYY, h a';
      parsedString = moment(_val).startOf('hours').format(momentSpecificity);
    } else if (_spec === 'w' || _spec === 'weeks') {
      momentSpecificity = 'MM-DD-YYYY';
      parsedString = moment(_val).startOf('weeks').add(1, 'days').format(momentSpecificity);
    } else if (_spec === 'm' || _spec === 'months') {
      momentSpecificity = 'MMM YYYY';
      parsedString = moment(_val).format(momentSpecificity);
    } else if (_spec === 'y' || _spec === 'years') {
      momentSpecificity = 'YYYY';
      parsedString = moment(_val).format(momentSpecificity);
    } else if (_spec === 'q' || _spec === 'quarters') {
      momentSpecificity = 'MMM YYYY';
      parsedString = moment(_val).startOf('quarters').format(momentSpecificity);
    }

    // console.log('Parsed String: ', parsedString)

    return parsedString;
  }


  getDateFromMomentLabel(_val, _spec) {
    let momentSpecificity = 'MM-DD-YYYY'; // default to day

    if (_spec === 'h' || _spec === 'hours') {
      momentSpecificity = 'MM-DD-YYYY, h a';
    } else if (_spec === 'w' || _spec === 'weeks') {
      momentSpecificity = 'MM-DD-YYYY';
    } else if (_spec === 'm' || _spec === 'months') {
      momentSpecificity = 'MMM YYYY';
    } else if (_spec === 'y' || _spec === 'years') {
      momentSpecificity = 'YYYY';
    } else if (_spec === 'q' || _spec === 'quarters') {
      momentSpecificity = 'MMM YYYY';
      // parsedString = moment(_val).startOf('quarters').format(momentSpecificity);
    }

    return moment(_val, momentSpecificity).toDate();
  }


  // If we want to convert to granularity label back to date but don't know the _spec option.
  parseGranularityLabelAsDate(_label: string) {
    if (_label == undefined) {
      // Handle cases where the label is null, undefined, or an empty string
      return new Date('Invalid Date');
    }

    // Try to parse the label as a date using different granularity formats.
    for (const format of this.granularityDateFormats) {
      const parsedDate = moment(_label, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.toDate();
      }
    }

    // If none of the formats match, return an invalid date
    return new Date('Invalid Date');
  }


  isGranularityLabelFormat(label) {
    if (label == undefined) return false;

    let isFormatted: boolean = false;

    // See if the passed label was already formatted and is a valid one set in granularityDateFormats.
    for (const format of this.granularityDateFormats) {
      const parsedDate = moment(label, format, true);
      if (parsedDate.isValid()) isFormatted = true;
    }

    // If already formatted with one of the granularity label formats will return true;
    return isFormatted;
  }


  getDateGranularityLabelPromise(_val, _spec) {
    return new Promise(async (resolve, reject) => {
      let momentSpecificity = 'MM-DD-YYYY'; // default to day

      let parsedString = moment(_val).format(momentSpecificity);
      // console.log('_spec: ', _spec);

      if (_spec === 'h' || _spec === 'hours') {
        momentSpecificity = 'MM-DD-YYYY, h a';
        parsedString = moment(_val).startOf('hours').format(momentSpecificity);
      } else if (_spec === 'w' || _spec === 'weeks') {
        momentSpecificity = 'MM-DD-YYYY';
        parsedString = moment(_val).startOf('weeks').add(1, 'days').format(momentSpecificity);
      } else if (_spec === 'm' || _spec === 'months') {
        momentSpecificity = 'MMM YYYY';
        parsedString = moment(_val).format(momentSpecificity);
      } else if (_spec === 'y' || _spec === 'years') {
        momentSpecificity = 'YYYY';
        parsedString = moment(_val).format(momentSpecificity);
      } else if (_spec === 'q' || _spec === 'quarters') {
        momentSpecificity = 'MMM YYYY';
        parsedString = moment(_val).startOf('quarters').format(momentSpecificity);
      }

      // console.log('Parsed String: ', parsedString)

      resolve(parsedString);
    });
  }


  // Used in report groups for field types we don't have cases for.
  sortNonSpecifiedData(_array, _fieldToSort) {
    return _array.sort((a, b) => {
      const valueA = a[_fieldToSort];
      const valueB = b[_fieldToSort];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }

      if (valueA instanceof Date && valueB instanceof Date) {
        return new Date(valueA).getTime() - new Date(valueB).getTime(); // Compare dates
      }

      // See if it is an object type with a name and try to sort by that.
      const getName = (obj) => (obj instanceof Object && obj.name) ? obj.name : null;
      const nameA = getName(valueA) || '';
      const nameB = getName(valueB) || '';

      return nameA.localeCompare(nameB);
    });
  }


  sortByDate(array, sortField) {
    array.sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a[sortField]).getTime() - new Date(b[sortField]).getTime();
    });

    return array;
  };


  // Simple groupBy that returns deep nested objects name field if present and replaces undefined fields with a message
  groupByRelate(array, keyInfo): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // console.log('array: ', array);
      // console.log('keyInfo: ', keyInfo);

      const key = (keyInfo['fieldName']) ? keyInfo['fieldName'] : keyInfo;
      // console.log('key: ', key);
      // console.log('keyInfo: ', keyInfo);

      let group = array.reduce((result, currentValue) => {
        let currentField = currentValue[key];

        if (key.indexOf('.') !== -1) {
          currentField = key.split('.').reduce((o, i) => o && o[i], currentValue);
        }

        let fieldToUse = null;

        if (keyInfo.fieldType === 'MultiRelate') {
          fieldToUse = currentField;
          const currentRecordNames = fieldToUse.map(_f => _f.name);
          // console.log('Current Record Names from data row: ', currentRecordNames);

          if (currentRecordNames && currentRecordNames.length) {
            currentRecordNames.forEach(_rn => {
              if (!result[_rn]) result[_rn] = [];
              result[_rn].push(currentValue);
            });
          } else {
            const noValueName = 'No Value';
            if (!result[noValueName]) result[noValueName] = [];
            result[noValueName].push(currentValue);
          }
        } else {
          if (currentField != undefined) {
            if (typeof currentField === 'object') {
              // console.log('Object field');

              if (currentField['name'] != undefined) fieldToUse = currentField['name'];
              else if (currentField['first_name'] != undefined && currentField['last_name'] != undefined) fieldToUse = `${currentField['first_name']} ${currentField['last_name']}`;
              else if (currentField['_id'] != undefined) fieldToUse = currentField['_id'];
              else fieldToUse = '*Data Missing';

            } else if (keyInfo.fieldType && (keyInfo.fieldType === 'Date' || keyInfo.fieldType === 'DateTime')) {
              // console.log("&&&&&& DATE &&&&&&&&");

              const dateGranularity = keyInfo.options['dateSpecificity'];
              fieldToUse = this.getDateGranularityLabel(currentField, dateGranularity);
            } else {
              // console.log('ELSE');

              fieldToUse = currentField;
            }
          } else {
            fieldToUse = (key === 'summation') ? 'Total' : '*Field Not Set';
          }

          if (!result[fieldToUse]) result[fieldToUse] = [];
          result[fieldToUse].push(currentValue);
        }

        return result;
      }, []); // {}

      // console.log('Resolving Group: ', group);

      resolve(group);
    });
  }


  // Advanced groupBy that can be passed deep nest properties and replaces undefined field with a message
  groupByNestedProperty(array, property): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let hash = [],
        props = property.split('.');
      for (let i = 0; i < array.length; i++) {
        const key = props.reduce((acc, prop) => {
          if (!acc[prop] || acc[prop] === undefined) return acc && '*Field Not Set';
          else return acc && acc[prop];
        }, array[i]);

        if (!hash[key]) hash[key] = [];
        hash[key].push(array[i]);
      }

      resolve(hash);
    });
  }


  deepClone(obj) {
    if (obj === null || obj === undefined || typeof obj !== 'object') return obj; // Just return the object itself.
    if (obj instanceof Date) return new Date(obj.getTime());  // Handle Date objects specifically. Clone the Date object by copying its time.

    // Create an array or object to hold value.
    const newObject = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      const value = obj[key];
      // Recursive call for nested objects & arrays.
      newObject[key] = this.deepClone(value);
    }

    return newObject;
  }


  showAchievementNotification(_notificationMessage: string) {
    this.snackBar.openFromComponent(MintNotificationComponent, {
      duration: 1000,
      // horizontalPosition: 'left',
      horizontalPosition: 'start', //'start' | 'center' | 'end' | 'left' | 'right'
      data: {
        class: 'success',
        message: _notificationMessage
      }
    });
  }


  createObjectId() {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);

    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
      return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
  }


  isMobileScreen() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return screenWidth <= 680;
  }


  async convertUrlsToLinks(stringToCheck): Promise<string> {
    return new Promise(async (resolve) => {
      let currentMessage = stringToCheck;
      let replacedText, replacePattern1, replacePattern2, replacePattern3;

      if (currentMessage.indexOf("<img") === -1) {
        // has an image. don't manipulate this

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = currentMessage.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
      } else {
        replacedText = stringToCheck;
      }

      resolve(replacedText);
    });
  }

  getDecodedParams(_parmObject) {
    const decodedParams = Object.keys(_parmObject).reduce((acc, key) => {
      const cleanKey = key.replace(/^amp;/, ""); // Remove 'amp;' prefix.
      acc[cleanKey] = decodeURIComponent(_parmObject[key]);
      return acc;
    }, {} as Record<string, any>);

    return decodedParams;
  }


  playNotificationSound(_soundToPlay: string, _popupMessage: string, _volumeLevel = 0.5) {
    if (_soundToPlay == undefined) _soundToPlay = '/uploads/system_images/sounds/message_notification_long.wav'; // Sound to be played for notification.
    if (_popupMessage != undefined) this.showNotification(_popupMessage);

    const audio = new Audio();
    audio.src = _soundToPlay;
    audio.volume = _volumeLevel; // adjusting volume because it is way too loud.
    audio.load();

    // DOMException: play() failed because the user didn't interact with the document first gets thrown when triggering audio.play() and stops function calling this from finishing.
    // So this will catch error to stop issue.
    const resp = audio.play();

    if (resp !== undefined) resp.then(_ => audio.play()).catch(error => console.log('Error playing audio: ', error));
  }
}