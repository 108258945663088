export class InteractiveDocumentInstance {
  _id: string;
  name: string;
  description: string;
  status: string;
  interactive_document;
  saveAsTemplate: boolean;
  relatedRecord: { // lead, account, etc.
    recordName: string,
    recordId: string,
    email: string
  };
  relatedModule: { // email, sms, call, etc.
    moduleType: string,
    moduleRecordId: string
  };
  relatedCredentialingRecordId: string;
  completeInOrder: boolean;
  onlyNotifyUponCompletion: boolean;
  recipients: any;
  // recipients: [
  //   {
  //     recipientId: number,
  //     recipientType: string,
  //     fromType: string,
  //     email: string
  //   }
  // ];
  doc_data: any;
  path: any;
  actions: any[];
  teams: [];
  values;
  client: any;
  dateSigned: Date;
  created_by: any;
  modified_by: any;
  assigned_to: any;
  deleted: boolean;  
  createdAt: Date;
  updatedAt: Date;
  hasSearchTerms: any;
  user_emails_involved: any; // used for searching recipients.
  email_involved: string; // used for searching recipients.
  currentStatusTerm: string; // Used for agreement status type sidenav search.
  usersInActions: any; // used for searching actions.
}
